import React, { useEffect, useState } from 'react';
import Constantes from "./Constantes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilaTablaProductos from './FilaTablaProductos';
import { useNavigate}  from 'react-router-dom';

function VerProductos(){
    const naviget=useNavigate();
    const [productos, setProductos]=useState([]);
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         productos: [],
    //     };
    // }
    useEffect(()=>{
        const execute = async()=>{
        const respuesta = await fetch(`${Constantes.RUTA_API_PRODUCTO}/obtener_productos.php`);
        const productos = await respuesta.json();
        setProductos(productos);
        };
        execute();
    },[]);

    // componentDidMount() {
    //     const respuesta = await fetch(`${Constantes.RUTA_API_PRODUCTO}/obtener_productos.php`);
    //     const productos = await respuesta.json();
    //     console.log(productos);
    //     setProductos(productos);
    // };
   
    function LogoutSubmit(){
        localStorage.setItem("login", false);
        localStorage.setItem("loginStatus", "Logged out Successfully" )
        naviget("/");
    }
        return (
            <div>
                <div className="column">
                    <h1 className="is-size-3">Ver Productos</h1>
                    <div onClick={LogoutSubmit}>Logout</div>
                    <ToastContainer></ToastContainer>
                </div>
                <div className="table-container">
                    <table className="table is-fullwidth is-bordered">
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Usos</th>
                                <th>Seguridad</th>
                                <th>CAS</th>
                                <th>Presentacion</th>
                                <th>HojaSeguridad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.map(producto => {
                                return <FilaTablaProductos key={producto.producto_id} producto={producto}></FilaTablaProductos>;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    
};

export default VerProductos;