import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Constantes from './Constantes';
class FilaTablaProductos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Si han eliminado este producto, no necesitamos mostrarlo
            eliminado: false,
        };
        this.redireccionarParaEditar = this.redireccionarParaEditar.bind(this);
        this.eliminar = this.eliminar.bind(this);
    }
    redireccionarParaEditar() {
        return <Navigate to={`/editarProductos/${this.props.producto.producto_id}`} />
    }
    async eliminar() {
        const resultado = await Swal.fire({
            title: 'Confirmación',
            text: `¿Eliminar "${this.props.producto.nombre}"?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3298dc',
            cancelButtonColor: '#f14668',
            cancelButtonText: 'No',
            confirmButtonText: 'Sí, eliminar'
        });
        // Si no confirma, detenemos la función
        if (!resultado.value) {
            return;
        }
        const respuesta = await fetch(`${Constantes.RUTA_API_PRODUCTO}/eliminar_producto.php?id=${this.props.producto_producto_id}`, {
            method: "DELETE",
        });
        const exitoso = await respuesta.json();
        if (exitoso) {
            toast('Producto eliminado ', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.setState({
                eliminado: true,
            });
        } else {
            toast.error("Error eliminando. Intenta de nuevo");
        }
    }
    render() {
        if (this.state.eliminado) {
            return null;
        }
        return (
            <tr>
                <td>{this.props.producto.codigo}</td>
                <td>{this.props.producto.nombre}</td>
                <td>{this.props.producto.descripcion}</td>
                <td>{this.props.producto.usos}</td>
                <td>{this.props.producto.seguridad}</td>
                <td>{this.props.producto.cas}</td>
                <td>{this.props.producto.presentacion}</td>
                <td>{this.props.producto.hoja_seguridad}</td>
              
                <td>
                    <Link to={`/editarproductos/${this.props.producto.producto_id}`} className="button is-info">Editar</Link>
                </td>
                <td>
                    <button onClick={this.eliminar} className="button is-danger">Eliminar</button>
                </td>
            </tr>
        );
    }
}

export default FilaTablaProductos;