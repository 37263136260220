import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Constantes from './Constantes';
import { ComponentLifecycle } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function EditarProductos() {

    const [producto, setProducto]=useState({});
    const {id} = useParams();
  

    async function fetchActivityData(id, setProducto) {
        try{
          const response = await fetch(`${Constantes.RUTA_API_PRODUCTO}/obtener_producto.php?id=${id}`);
          const results = await response.json()
          setProducto(results)
        }
        catch(err){
          console.log(err)
        }
        finally{
          console.log("Fetch completed")
        }
      };

      useEffect(()=>{
        fetchActivityData(id, setProducto)
      }, [id, setProducto]);

     async function manejarEnvioDeFormulario(evento) {

        evento.preventDefault();
        // Codificar nuestro videojuego como JSON
        const cargaUtil = JSON.stringify(producto);
        console.log(cargaUtil);
        // ¡Y enviarlo!
        const respuesta = await fetch(`${Constantes.RUTA_API_PRODUCTO}/actualizar_producto.php`, {
            method: "PUT",
            body: cargaUtil,
        });
        const exitoso = await respuesta.json();
        console.log(exitoso);
        if (exitoso) {
            // toast('Producto guardado', {
            //     position: "top-left",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // 
            alert("listo");
        } else {
            toast.error("Error guardando. Intenta de nuevo");
            console.log("no se puede");
        }
    }
      
    const manejarCambio=(e)=>{
        const{ name, value }=e.target;
        setProducto({ ...producto, [name]: value });
        console.log(producto)
    }

   
    

      return (
        <div className="column is-one-third">
            <h1 className="is-size-3">Editando Producto</h1>
           
            <form className="field" onSubmit={manejarEnvioDeFormulario}>
                <div className="form-group">
                    <label className="label" htmlFor="codigo">Codigo:</label>
                    <input autoFocus required placeholder="Codigo" type="text" name="codigo" id="codigo" onChange={manejarCambio} value={producto.codigo} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="nombre">Nombre:</label>
                    <input autoFocus required placeholder="Nombre" type="text" name="nombre" id="nombre" onChange={manejarCambio} value={producto.nombre} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="linea">Linea:</label>
                    <input autoFocus required placeholder="Linea" type="number" name="linea_id" id="linea" onChange={manejarCambio} value={producto.linea_id} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="familia">Familia:</label>
                    <input autoFocus required placeholder="Familia" type="number" name="familia_id" id="familia" onChange={manejarCambio} value={producto.familia_id} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="descripcion">Descripción:</label>
                    <textarea autoFocus placeholder="Descripcion" type="text" name="descripcion" id="descripcion" onChange={manejarCambio} value={producto.descripcion} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="codigo">Usos:</label>
                    <textarea autoFocus placeholder="Usos" type="text" name="usos" id="usos" onChange={manejarCambio} value={producto.usos} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="seguridad">Seguridad:</label>
                    <textarea autoFocus placeholder="Seguridad" type="text" name="seguridad" id="seguridad" onChange={manejarCambio} value={producto.seguridad} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="cas">CAS:</label>
                    <input autoFocus placeholder="CAS" type="text" name="cas" id="cas" onChange={manejarCambio} value={producto.cas} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="presentacion">Presentación:</label>
                    <input autoFocus placeholder="Presentación" type="text" name="presentacion" id="presentacion" onChange={manejarCambio} value={producto.presentacion} className="input" />
                </div>
                <div className="form-group">
                    <label className="label" htmlFor="presentacion">Hoja Seguridad:</label>
                    <input autoFocus placeholder="Hoja Seguridad" type="text" name="hoja_seguridad" id="hojaseguridad" onChange={manejarCambio} value={producto.hoja_seguridad} className="input" />
                </div>
               
                <div className="form-group">
                    <button className="button is-success mt-2" type="submit">Guardar</button>
                    &nbsp;
                    <Link to="/VerProductos" className="button is-primary mt-2">Volver</Link>
                </div>
            </form>
        </div>
    );

  
};
export default EditarProductos;