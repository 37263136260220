import "./App.css";
import Protected from "./Protected";
import Login from "./Login";
import VerProductos from "./VerProductos";
import EditarProductos from "./EditarProductos";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/verproductos" element={<Protected Component={VerProductos} />} />
          <Route path="/editarproductos/:id" element={<Protected Component={EditarProductos} />} />
        </Routes>
        </Router>
    </div>
  );
}

export default App;
