import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const naviget = useNavigate();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("hola");

  const handleInputChange = (e, type) => {
    switch (type) {
      case "user":
        setError("");
        setUser(e.target.value);
        if (e.target.value === "") {
          setError("Usuario en blanco");
        }
        break;
      case "password":
        setError("");
        setPassword(e.target.value);
        if (e.target.value === "") {
          setError("Password en Blanco");
        }
        break;
      default:
    }
  };

  useEffect(() => {
    let login=localStorage.getItem("login");
    if(login){
      naviget("/verproductos");
    }
    let loginStatus=localStorage.getItem("loginStatus");
    if(loginStatus){
      setError(loginStatus);
      setTimeout(function(){
        localStorage.clear();
        window.location.reload();
      },3000);
      
    }
    setTimeout(function () {
      setMsg("");
    }, 5000);
  }, [msg]);

  function loginSubmit() {
    if (user !== "" && password !== "") {
      const url = "https://www.ikhor-coding.com/login/login.php";
      let headers = {
        Accept: "application/json",
        "Content-type": "application/json",
      };
      let data = {
        user: user,
        password: password,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (
            response[0].result === "Invalid Username!" ||
            response[0].result === "Invalid Password!"
          ) {
            setError(response[0].result);
          } else {
            setMsg(response[0].result);
            setTimeout(function () {
              localStorage.setItem("login", true);
              naviget("/VerProductos");
            }, 5000);
          }
        })
        .catch((err) => {
          setError(err);
          console.log(err);
        });
    }
  }
  return (
    <div>
      <p>{error !== "" ? <span>{error}</span> : <span>{msg} </span>}</p>
      <div className="form">
        <label>Username</label>
        <input
          type="text"
          value={user}
          onChange={(e) => handleInputChange(e, "user")}
          required
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => handleInputChange(e, "password")}
          required
        />
        <input
          className="button"
          type="submit"
          defaultValue="Login"
          onClick={loginSubmit}
        ></input>
      </div>
    </div>
  );
}

export default Login;
